var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart ratio", style: { paddingBottom: _vm.padding + "%" } },
    [
      _c("div", { staticClass: "chart-wrap" }, [
        _c("canvas", { ref: "chart" }),
        _vm._v(" "),
        _c("div", { attrs: { hidden: "" } }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }